import { Dropdown } from 'bootstrap';
import css from "./styles.scss";

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../images/sprites/', true, /\.svg$/));

document.querySelectorAll('[data-sprite-url]').forEach(function(el){
    fetch(el.dataset.spriteUrl).then(res => {
        return res.text();
    }).then(data => {
        el.innerHTML = data;
    });
})
